import React from 'react'

const className = 'font-serif text-h4 leading-tight antialiased'
export const H4ClassName = className

const H4: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
> = ({ children, className, ...other }) => {
  return (
    <h4
      className={H4ClassName + (className ? ` ${className}` : '')}
      {...other}
    >
      {children}
    </h4>
  )
}

export default H4
