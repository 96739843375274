// React imports
import { Link } from '@remix-run/react'
import React, { useContext } from 'react'
import Button from '~/components/buttons/Button'
import Swiper from '~/components/carousels/Swiper'
import SectionGrayFullWidth from '~/components/containers/SectionGrayFullWidth'
import ImageOrVideo from '~/components/images/ImageOrVideo'
import { ModalsContext } from '~/components/modals/Modals'

// Remix imports

// Third party imports

// Generated imports

// App imports
import H2 from '~/components/typography/H2'
import { getTranslations, type PickTranslations } from '~/i18n.server'
import type { Language } from '~/i18n.universal'
import LoginSignupModal from '~/routes/$lang.modals/login-signup'
import urlReverse from '~/utils/urlReverse'
import H3 from '../typography/H3'
import H4 from '../typography/H4'

interface Props {
  lang: Language
  trans: JoinOurTradeProgramTrans
  className?: string
  images: string[]
  hideLinkToTradeViewButton?: boolean
}

const JoinOurTradeProgram: React.FC<Props> = ({
  images,
  lang,
  className,
  trans,
  hideLinkToTradeViewButton,
}) => {
  const modalsCtx = useContext(ModalsContext)

  if (images.length != 3) {
    return null
  }

  const data = [
    {
      title: trans.JoinTradeText1,
      overlay: trans.JoinTradeOverlay1,
      image: images[0],
    },
    {
      title: trans.JoinTradeText2,
      overlay: trans.JoinTradeOverlay2,
      image: images[1],
    },
    {
      title: trans.JoinTradeText3,
      overlay: trans.JoinTradeOverlay3,
      image: images[2],
    },
  ]

  const elements = data.map((item, index) => {
    return (
      <div
        key={index}
        className="text-left"
      >
        <div className="relative aspect-square w-full">
          <ImageOrVideo
            alt={item.title}
            className="w-full"
            transformation={{
              width: `${499 * 2}`,
              height: `${499 * 2}`,
              bg: 'FFFFFF',
            }}
            path={item.image}
            maxWidth={499}
            maxHeight={499}
          />
          <div className="absolute top-0 left-0 h-full w-full bg-black opacity-50" />
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
            <H4 className="text-center text-white">{item.overlay}</H4>
          </div>
        </div>
        <H3 className="mt-3">{item.title}</H3>
      </div>
    )
  })

  return (
    <SectionGrayFullWidth
      className={'py-16 text-center' + (className ? ` ${className}` : '')}
    >
      <div className="flex justify-center">
        <H2 className="max-w-[848px]">
          {trans.FromSmallSpacesToLargeScaleText}
        </H2>
      </div>

      <Swiper
        items={elements}
        gridCols="md:grid-cols-3"
        className="mt-8"
      />

      <div className="mt-6 flex w-full flex-col items-center justify-center">
        <Button
          size="large"
          className="w-full max-w-[400px]"
          onClick={() => {
            modalsCtx?.show(
              'LoginSignupModal',
              <LoginSignupModal initialShowForm="tradeSignup" />
            )
          }}
        >
          {trans.JoinOurTradeProgram}
        </Button>

        {!hideLinkToTradeViewButton && (
          <Link
            className="mt-6 w-full max-w-[400px]"
            to={urlReverse('tradeView', { lang })}
            prefetch="intent"
          >
            <Button
              size="large"
              secondary={true}
              className="w-full"
            >
              {trans.ViewTradeBenefits}
            </Button>
          </Link>
        )}
      </div>
    </SectionGrayFullWidth>
  )
}

export default JoinOurTradeProgram

// ============================================================================
// TRANS ======================================================================
// ============================================================================
export type JoinOurTradeProgramTrans = PickTranslations<
  | 'ViewTradeBenefits'
  | 'JoinOurTradeProgram'
  | 'FromSmallSpacesToLargeScaleText'
  | 'JoinTradeText1'
  | 'JoinTradeText2'
  | 'JoinTradeText3'
  | 'JoinTradeOverlay1'
  | 'JoinTradeOverlay2'
  | 'JoinTradeOverlay3'
>

export const getJoinOurTradeProgramTrans = (lang: Language) => {
  return getTranslations(lang, [
    'ViewTradeBenefits',
    'JoinOurTradeProgram',
    'FromSmallSpacesToLargeScaleText',
    'JoinTradeText1',
    'JoinTradeText2',
    'JoinTradeText3',
    'JoinTradeOverlay1',
    'JoinTradeOverlay2',
    'JoinTradeOverlay3',
  ])
}
