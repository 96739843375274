import Container from '~/components/containers/Container'

interface Props {
  children: React.ReactNode
  className?: string
}

const SectionGrayFullWidth: React.FC<Props> = ({ className, children }) => {
  return (
    <div
      className={
        // this undoes the px-[20px] from the <Container> so that the gray
        // background extends to the edge of the screen
        'relative left-[50%] right-[50%] ml-[-50vw] mr-[-50vw] w-[100vw] bg-section-bg px-0' +
        (className ? ` ${className}` : '')
      }
    >
      <Container>{children}</Container>
    </div>
  )
}

export default SectionGrayFullWidth
