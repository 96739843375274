// React imports
import { Link } from '@remix-run/react'
import React from 'react'
import Swiper from '~/components/carousels/Swiper'
import ImageOrVideo from '~/components/images/ImageOrVideo'
import H1WithArrowLink from '~/components/links/H1WithArrowLink'
import LinkOrInternalLinkOrDiv from '~/components/links/LinkOrInternalLinkOrDiv'
import H1 from '~/components/typography/H1'
import H2 from '~/components/typography/H2'
import P1 from '~/components/typography/P1'
import P2 from '~/components/typography/P2'

// Remix imports

// Third party imports

// Generated imports

// App imports
import type { ConsultancyProjectFragment } from '~/generated/graphql'
import type { PickTranslations } from '~/i18n.server'
import { getTranslations } from '~/i18n.server'
import type { Language } from '~/i18n.universal'
import urlReverse from '~/utils/urlReverse'

interface Props {
  lang: Language
  trans: SelectedProjectsTrans
  className?: string
  data: ConsultancyProjectFragment[]
  imageFieldName: 'featuredOnHomepageImage' | 'featuredOnTradeImage'
}

const SelectedProjects: React.FC<Props> = ({
  lang,
  data,
  className,
  trans,
  imageFieldName,
}) => {
  if (data.length != 2) {
    return null
  }

  const elements = data.map((item, index) => {
    return (
      <div
        key={index}
        className="text-left"
      >
        <div className="relative aspect-376/520 w-full">
          <LinkOrInternalLinkOrDiv
            to={item.absoluteUrl}
            className="w-full"
          >
            <ImageOrVideo
              alt={item.name || ''}
              className="w-full"
              transformation={{
                width: `${376 * 2}`,
                height: `${520 * 2}`,
                bg: 'FFFFFF',
              }}
              path={item[imageFieldName] || ''}
              maxWidth={376}
              maxHeight={520}
            />
            <div className="group absolute top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center bg-[rgba(0,0,0,0)] transition-all duration-500 ease-in-out hover:bg-[rgba(0,0,0,0.8)] ">
              <div className="flex flex-col items-center justify-center opacity-0 transition-all duration-500 ease-in-out group-hover:opacity-100">
                <H1
                  as="p"
                  className="font-bold text-white"
                >
                  {item.name}
                </H1>
                <P2 className="text-white opacity-80">{item.location}</P2>
              </div>
            </div>
          </LinkOrInternalLinkOrDiv>
        </div>
        <div className="block md:hidden">
          <P1 className="font-serif font-bold">{item.name}</P1>
          <P2 className="text-brand-gold">{item.location}</P2>
        </div>
      </div>
    )
  })

  return (
    <div className={className + ' text-center'}>
      <Link to={urlReverse('consultancyProjectsAll', { lang })}>
        <H2>{trans.SelectedProjects}</H2>
      </Link>

      <Swiper
        className="mx-auto mt-7 max-w-[800px]"
        items={elements}
        gridCols="md:grid-cols-2"
      />

      <H1WithArrowLink
        className="mt-7"
        text={trans.DiscoverMoreProjects}
        link={urlReverse('consultancyProjectsAll', { lang })}
      />
    </div>
  )
}

export default SelectedProjects

// ============================================================================
// TRANS ======================================================================
// ============================================================================
export type SelectedProjectsTrans = PickTranslations<
  'DiscoverMoreProjects' | 'SelectedProjects'
>

export const getSelectedProjectsTrans = (lang: Language) => {
  return getTranslations(lang, ['DiscoverMoreProjects', 'SelectedProjects'])
}
